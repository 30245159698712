<template>
  <div class="about">
    <a-row>
      <a-col :span="8">
        <img src="@/assets/images/about.png" alt="" />
      </a-col>
      <a-col :span="16">
        <div class="content">
          <p>
            安徽农管家农业服务有限公司是定位于农村土地托管、农业农村信息化、农业产业规划运营、农产品耕、种、管、收、加工、仓储、销售等一体的现代农业社会化服务公司。
          </p>
          <p>
            公司成立于2020年12月，注册资本金1000万元，由安徽华印机电股份公司和成启信息科技有限公司联合省内七家农业服务合作社共同成立。公司目前拥有20余人的农业服务团队，公司涉及到农技、遥感科技、大数据等专业，并与多个知名高校和农业科学院形成长期战略合作关系，为农村集体经济组织开展的农业生产统一托管经营提供服务，为壮大集体经济并实现农民增收，破解粮食安全问题提供了有效途径。公司下设客服服务部、农业技术服务部、金融保险服务部和综合管理部，并设立了为农服务大厅，办理各项业务。
          </p>

          <p>
            公司将继续秉承“经营统一化、农田规模化、生产专业化、耕作机械化、作物良种化、污染减量化、销售订单化、秸秆资源化”的经营理念，持续拓宽服务形式，提高生产效益。在做好农业生产托管服务的前提下，组织积极开展延伸性综合农业社会化服务。拉长产业链，提升效益链，除提供耕、种、管、收等环节托管服务外，试点开展良种培育、稻米加工等产业服务。
          </p>
          <p>
            安徽农管家农业服务有限公司将依托完备的农资服务产业链和全方位的经营网络，通过“农管家”品牌，为农民提供“打包农业增产服务”，积极参与新农村建设，在新形势下为“三农”发展作出新贡献！
          </p>
        </div>
      </a-col>
    </a-row>
    <div class="contact">
      <div class="left">
        <div class="title">联系<span>我们</span></div>
        <div class="en">CONTACT US</div>
      </div>
      <div class="middle">
        <p><a-icon type="phone" />客服电话 : 0554-2212138(周一至周六 9:30 - 18:00)</p>
        <p><a-icon type="mail" />投诉与建议 : ngj@rh.com</p>
        <p><a-icon type="environment" />公司地址 : 淮南市田家庵区淝水大道东150米</p>
      </div>
    </div>
  </div>
</template>

<script type="text/ecmascript-6">
export default {
  data() {
    return {

    }
  },
  components: {

  }
}
</script>

<style scoped lang="scss">
.about {
  padding: 30px 0;
  img {
    width: 100%;
  }
  .content {
    line-height: 2em;
    font-size: 12px;
    text-indent: 2em;
    padding-top: 13%;
    color: #333;
  }
  .contact {
    overflow: hidden;
    margin-top: 40px;
    display: flex;
    .left {
      z-index: 99;
      flex: 24%;
      .title {
        font-size: 22px;
        color: #2f2e2e;
        line-height: 3em;
        span {
          margin-left: 10px;
          color: #008840;
        }
      }
      .en {
        margin-left: 54px;
        font-size: 47px;
        color: #e1e5f0;
        border-top: 5px solid #008840;
        line-height: 1.6em;
        font-family: "Myriad Pro";
        font-weight: bolder;
      }
    }
    .middle {
      padding: 40px 0 40px 210px;
      margin-left: -160px;
      flex: 76%;
      background: url("~@/assets/images/about_contact.png") no-repeat 98% center #f4f5f9;
      i {
        margin-right: 10px;
        color: #008840;
        font-weight: bold;
      }
    }
  }
}
</style>
